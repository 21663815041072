export default [
  {
    path: "/discount/list",
    name: "discount-list",
    component: () =>
      import("@/views/discount/discounts-list/DiscountsList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/discount/create",
    name: "discount-create",
    component: () =>
      import("@/views/discount/discounts-edit/DiscountsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/discount/edit/:id",
    name: "discount-edit",
    component: () =>
      import("@/views/discount/discounts-edit/DiscountsEdit.vue"),
    meta: {
      action: "read",
      resource: "Auth",
    },
  },
];
