import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueExcelXlsx from "vue-excel-xlsx";
import { getCorrectDateTime } from "@/libs/helper";
import {
  flatDateConfig,
  flatDateConfigForBirth,
  futureDateConfig,
  dateRangeConfig,
} from "@/libs/helper";

Vue.prototype.getCorrectDateTime = getCorrectDateTime;
Vue.prototype.flatDateConfig = flatDateConfig;
Vue.prototype.flatDateConfigForBirth = flatDateConfigForBirth;
Vue.prototype.futureDateConfig = futureDateConfig;
Vue.prototype.dateRangeConfig = dateRangeConfig;
Vue.use(VueExcelXlsx);

import router from "./router";
import store from "./store";
import App from "./App.vue";
import CKEditor from "ckeditor4-vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/acl";
import "@/libs/sweet-alerts";
import ElementUI from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);

import "element-ui/lib/theme-chalk/index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faUser,
  faIdBadge,
  faPassport,
  faCakeCandles,
  faEnvelope,
  faPhone,
  faPen,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUser,
  faIdBadge,
  faPassport,
  faCakeCandles,
  faEnvelope,
  faPhone,
  faPen,
  faPlus,
  faMinus
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(ElementUI);
Vue.use(CKEditor);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
